.roww1 {
    margin-top: 0.625rem;
    display: grid;
    grid-template-columns: 4fr 3fr 2fr;
    grid-template-rows: 5fr 4fr;
    grid-template-areas:
        'a b c'
        'd e c';
}

.bar {
    background-color: #203864;
    height: 100%;
}

.radar {
    background-color: #203864;
    height: 100%;
}
.spline {
    background-color: #203864;
}
.r1 {
    grid-area: b;
}

.bar1 {
    grid-area: a;
}
.table {
    grid-area: e;
    margin-left: -14.625rem;
}
.perform {
}
.rank {
    color: rgb(115, 232, 49);
}
.perform1 {
    /* grid-area: c; */
    background-color: #203864;
    height: 100%;
    /* margin-left: -0.938rem; */
}

.r2 {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    gap: 20px;
}

.spline {
    background-color: #203864;
}

.spline1 {
    grid-area: d;
}
.mydiv {
    background-color: #203864;
    padding: 20px;
    text-align: left;
    margin: 10px;
}

.img {
    height: 5vh;
    border-radius: 100%;
}

.img1 {
    /* padding-left: 2%; */
    height: 4vh;
}

.online {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    gap: 30px;
    box-shadow: none;
}

.name {
    color: rgb(6, 231, 8);
    font-weight: normal;
}

.league {
}
.sname {
    color: rgb(6, 231, 8);
}
.position {
    color: rgb(6, 231, 8);
}

.age,
.height,
.weight,
.rank {
    /* padding-left: 50px; */
    color: rgb(6, 231, 8);
}
