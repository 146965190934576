.TableContainer {
    overflow: auto;
    /* scrollbar-width: thin; */
    /* scrollbar-color: #ccc #f4f4f4; */
}

/* .TableContainer::-webkit-scrollbar {
    width: 5px;
    height: 5px;
} */

/* .TableContainer::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
} */

.TableContainer::-webkit-scrollbar-thumb:hover {
    background-color: #999;
}
.main {
    width: 50%;
}

.player {
    color: yellow;
}

.row {
    display: flex;
    justify-content: left;
}

.row1 {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-top: 5%;
}

.row2 {
    display: flex;
    /* padding-left: 20%; */
    justify-content: left;
    flex-direction: column;
}

.row3 {
    display: flex;
    padding-left: 5%;
    justify-content: left;
    flex-direction: column;
}

.c1 {
    /* padding-left: 25%; */
}

.c2 {
    /* padding-left: 3.5%; */
}

.a1 {
    /* padding-left: 25%; */
}

.a2 {
    /* padding-left: 3.5%; */
}
